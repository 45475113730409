// ����ҳ��ͼ
.bannerTabbar{
    height: 500px;
    overflow: hidden;
    img{
        width: 100%;
    }
}

//���̷�ɻ������ŵĶ��������б�ҳ��
.newsOmit{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}


// ����ҳ����
.content{
    max-width: 1060px;
    margin: 5px auto 50px auto;
    font-family: auto;
    .title{
        text-align: center;
        font-size: 30px;
        font-family: cursive;
        font-weight: bold;
        margin-bottom: 40px;
    }
    .newsImg{
        overflow: hidden;
        margin: 30px auto;
        border-radius: 5px;
        max-width: 1000px;
        max-height: 600px;
        .img{
            display: block;
            margin: 0 auto;
        }
        .alt-desc{
            font-size: 13px;
            color: #8d8d8d;
            margin: 3px 0 0 0;
            text-align: center;
        }
    }
    .paragraph{
        font-size: 15px;
        font-family: monospace;
        line-height: 24px;
        text-indent: 32px;
    }
    .cen-align{
        text-align: center; 
    }
    
}